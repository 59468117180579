import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBalanceScale } from "@fortawesome/free-solid-svg-icons"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Card, Carousel, Col, Container, Jumbotron, Row } from "react-bootstrap"
import * as styles from "./index.module.css"
import Layout from "../../components/layout"

export default function OurPractice() {
  const imageHeight = 200

  return (
    <Layout>
      <Jumbotron className="bg-secondary mb-0 p-4 text-center" fluid>
        <FontAwesomeIcon
          className="text-light-green"
          icon={faBalanceScale}
          size="6x"
        />
        <h1 className="fw-bold h2 mt-4">
          <span className="text-light-green">Legal Services</span> Second to
          None
        </h1>
        <h2 className="h5 mt-3">
          Meet Our <span class="text-light-green">Team</span>
        </h2>
        <p className="mt-3">
          Our unique team is well equipped to help you through any family law
          issue.
        </p>
      </Jumbotron>
      <Container className="p-4 text-center" fluid>
        <Row className="justify-content-center">
          <Col className="p-md-3" md={5}>
            <Card className="bg-light-green h-100" text="dark">
              <Card.Body>
                <Card.Title className={styles.cardTitle}>34</Card.Title>
                <Card.Text>Years of Experience</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="mt-3 mt-md-0 p-md-3" md={5}>
            <Card bg="secondary" className="h-100">
              <Card.Body>
                <Card.Title className={styles.cardTitle}>100%</Card.Title>
                <Card.Text> Positive Feedback</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="mt-3 mt-md-0 p-md-3" md={10} fluid>
            <Card bg="white" className="h-100" text="dark">
              <Card.Body>
                <Card.Title className={styles.cardTitle}>
                  All Walks Of Life
                </Card.Title>
                <Card.Text>
                  Mark has worked with well-known celebrities, professionals of
                  diverse educational backgrounds, municipal governments, all
                  types of small businesses and countless everyday people who
                  sought his advice in matters involving divorce, child custody,
                  family law, entertainment law, small business issues or the
                  recovery of damages following a personal injury.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="bg-secondary mb-0 p-4 p-md-5">
        <h1 className="fw-bold h4">Our Team Of Legal Professionals</h1>
        <h2 className="h5 text-light-green">Mark Sterling Gober</h2>
        <h3 className="h6 text-dark">Attorney at Law</h3>
        <Carousel className="mt-3">
          <Carousel.Item className="text-center">
            <StaticImage
              alt="Mark"
              height={imageHeight}
              placeholder="blurred"
              src="../../images/profile1.jpeg"
            />
          </Carousel.Item>
          <Carousel.Item className="text-center">
            <StaticImage
              alt="Mark"
              height={imageHeight}
              placeholder="blurred"
              src="../../images/profile2.jpeg"
            />
          </Carousel.Item>
        </Carousel>
        <p className="mt-3">
          Mark established his practice in Tuscaloosa, Alabama in 2005. Since
          then, Mark Sterling Gober, LLC has become well known throughout West
          Central Alabama for providing personal legal representation which is
          second to none. The firm has been listed among Lawyers of Distinction
          (as published in The New York Times, CNN, The Huffington Post and USA
          Today). Attorney and Practice Magazine has designated Mark Sterling
          Gober, LLC as one of Alabama’s Top 10 Family Law Practices. The
          practice has also been recognized by the National Association of
          Family Law Attorneys for exceptional and outstanding client
          representation in matters involving child custody, divorce and family
          law.
        </p>
        <h2 className="h5 text-light-green">Allyson Causey Kimbrel</h2>
        <h3 className="h6 text-dark">Legal Assistant, Office Manager</h3>
        <Carousel className="mt-3">
          <Carousel.Item className="text-center">
            <StaticImage
              alt="Allison"
              height={imageHeight}
              placeholder="blurred"
              src="../../images/allison.jpeg"
            />
          </Carousel.Item>
          <Carousel.Item className="text-center">
            <StaticImage
              alt="Allison"
              height={imageHeight}
              placeholder="blurred"
              src="../../images/allison2.jpeg"
            />
          </Carousel.Item>
        </Carousel>
        <p className="mt-3">
          Allyson joined the firm as our Legal Assistant and Office Manager in 2012.
          Allyson is known throughout the Tuscaloosa legal community as one of
          the best in her field. She brings to her position the perfect
          combination of empathy and professionalism.
        </p>
        <h2 className="h5 text-light-green">Piper</h2>
        <h3 className="h6 text-dark">Client Liason, Service Cat</h3>
        <Carousel className="mt-3">
          <Carousel.Item className="text-center">
            <StaticImage
              alt="Piper"
              height={imageHeight}
              placeholder="blurred"
              src="../../images/piper.jpeg"
            />
          </Carousel.Item>
          <Carousel.Item className="text-center">
            <StaticImage
              alt="Piper"
              height={imageHeight}
              placeholder="blurred"
              src="../../images/piper2.jpeg"
            />
          </Carousel.Item>
          <Carousel.Item className="text-center">
            <StaticImage
              alt="Piper"
              height={imageHeight}
              placeholder="blurred"
              src="../../images/piper3.jpeg"
            />
          </Carousel.Item>
        </Carousel>
        <p className="mt-3 mb-0">
          Piper joined us in 2015 as our client liason/service cat. Piper is a
          client favorite and looks forward to greeting you on your visit to
          Mark Sterling Gober, LLC.
        </p>
      </Container>
      <Container className="bg-white mt-md-4 p-4 text-center text-dark">
        <p className={styles.cardTitle}>Awards and Recognition</p>
        <Row className="align-items-center mt-3">
          <Col lg={6}>
            <Row className="align-items-center">
              <Col className="p-md-3" md={6}>
                <StaticImage
                  alt="10 Best Attorneys"
                  className="border-black"
                  placeholder="blurred"
                  src="../../images/award-1.jpeg"
                />
              </Col>
              <Col className="mt-3 mt-md-0 p-md-3" md={6}>
                <StaticImage
                  alt="2019 The Tuscaloosa News Best of the Best"
                  className="border-black"
                  placeholder="blurred"
                  src="../../images/award-2.jpeg"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <Row className="align-items-center">
              <Col className="mt-3 mt-md-0 p-md-3" md={6}>
                <div className="bg-white border-black p-3">
                  <StaticImage
                    alt="Lawyers of Distinction 2019"
                    placeholder="blurred"
                    src="../../images/award-3.png"
                  />
                </div>
              </Col>
              <Col className="mt-3 mt-md-0 p-md-3" md={6}>
                <StaticImage
                  alt="Top 10 Family Law Attorney"
                  className="border-black"
                  placeholder="blurred"
                  src="../../images/award-4.jpeg"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
